import * as React from 'react';
import { graphql } from 'gatsby';
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

// @ts-ignore
import * as styles from './index.module.css';

import Layout from '../components/layout';
import Hr from '../components/Hr/hr';
import ogImg from '../images/og-image.png';
import SEO from '../components/seo';

const TermsAndConditionsPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const isPl = language === 'pl';
  const email = isPl ? 'kontakt@getbarfplan.com' : 'contact@getbarfplan.com';
  const mailto = `mailto:${email}`;

  return (
    <Layout>
      <SEO
        title={t('Terms and Conditions')}
        ogUrl="https://getbarfplan.com/"
        imageUrl={ogImg}
      />

      <div className="container bg-white ph-60 md:ph-30 xs:ph-10">
        <h1>
          <Trans>Terms and Conditions</Trans>
        </h1>

        {isPl && <h2>Warunki korzystania z usług</h2>}

        <p>
          <Trans i18nKey="tc-1">
            By downloading or using the app BarfPlan, these terms will
            automatically apply to you – you should make sure therefore that you
            read them carefully before using the app. You’re not allowed to copy
            or modify the app, any part of the app, or our trademarks in any
            way. You’re not allowed to attempt to extract the source code of the
            app, and you also shouldn’t try to translate the app into other
            languages or make derivative versions. The app itself, and all the
            trademarks, copyright, database rights, and other intellectual
            property rights related to it, still belong to{' '}
            <i>Vastec Tomasz Nieżurawski</i>.
          </Trans>
        </p>

        <p>
          <Trans i18nKey="tc-2">
            {' '}
            <i>Vastec Tomasz Nieżurawski</i> is committed to ensuring that the
            app is as useful and efficient as possible. For that reason, we
            reserve the right to make changes to the app or to charge for its
            services, at any time and for any reason. We will never charge you
            for the app or its services without making it very clear to you
            exactly what you’re paying for.
          </Trans>
        </p>
        <p>{t('tc-3')}</p>
        <p>{t('tc-4')}</p>
        <p>
          <Trans i18nKey="tc-5">
            You should be aware that there are certain things that
            <i>Vastec Tomasz Nieżurawski</i> will not take responsibility for.
            Certain functions of the app will require the app to have an active
            internet connection. The connection can be Wi-Fi, or provided by
            your mobile network provider, but <i>Vastec Tomasz Nieżurawski</i>{' '}
            cannot take responsibility for the app not working at full
            functionality if you don’t have access to Wi-Fi, and you don’t have
            any of your data allowance left.
          </Trans>
        </p>
        <p>{t('tc-6')}</p>
        <p>
          <Trans i18nKey="tc-7">
            Along the same lines, <i>Vastec Tomasz Nieżurawski</i> cannot always
            take responsibility for the way you use the app i.e. You need to
            make sure that your device stays charged – if it runs out of battery
            and you can’t turn it on to avail the Service,{' '}
            <i>Vastec Tomasz Nieżurawski</i> cannot accept responsibility.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="tc-8">
            With respect to <i>Vastec Tomasz Nieżurawski</i> responsibility for
            your use of the app, when you’re using the app, it’s important to
            bear in mind that although we endeavor to ensure that it is updated
            and correct at all times, we do rely on third parties to provide
            information to us so that we can make it available to you.{' '}
            <i>Vastec Tomasz Nieżurawski</i> accepts no liability for any loss,
            direct or indirect, you experience as a result of relying wholly on
            this functionality of the app.
          </Trans>
        </p>
        <p>
          <Trans i18nKey="tc-9">
            At some point, we may wish to update the app. The app is currently
            available on Android & iOS – the requirements for both systems (and
            for any additional systems we decide to extend the availability of
            the app to) may change, and you’ll need to download the updates if
            you want to keep using the app. <i>Vastec Tomasz Nieżurawski</i>{' '}
            does not promise that it will always update the app so that it is
            relevant to you and/or works with the Android & iOS version that you
            have installed on your device.
          </Trans>
        </p>
        <p>{t('tc-10')}</p>

        <Hr />

        <section>
          <h2>
            <Trans>Fee for using the service</Trans>
          </h2>

          <p>{t('tc-11')}</p>
          <p>{t('tc-12')}</p>
          <p>{t('tc-13')}</p>
          <p>{t('tc-14')}</p>
          <p>{t('tc-15')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Changes to these Terms and Conditions</Trans>
          </h2>

          <p>{t('tc-16')}</p>
        </section>

        <Hr />

        <section>
          <h2>
            <Trans>Contact Us</Trans>
          </h2>

          <div />
          <p
            dangerouslySetInnerHTML={{
              __html: t('tc-17', {
                emailLink: `<a href="${mailto}" target="_blank">${email}</a>`,
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </section>
      </div>
    </Layout>
  );
};

export default TermsAndConditionsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
